.single_job_listing{
	.application-deadline{
		list-style: none;
		&::before{
			font-size: var(--jobbox-font-xs);
		}
		label{
			margin-bottom: 0;
		}
	}
}